import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// eslint-disable-next-line
import 'react-calendar/dist/Calendar.css';
import { NAVIGATION_URL } from 'utils/constants';
import AdminEvaluationsPage from 'pages/administator/admin-evaluations';
import AdminTestLibrariesDetailPage from 'pages/administator/admin-test-libraries-detail';
import AdminTestLibrariesNewPage from 'pages/administator/admin-test-libraries-new';
import AdminTestLibrariesPage from 'pages/administator/admin-test-libraries';
import AdminUsersPage from 'pages/administator/admin-users';
import CertificationDetailsPage from 'pages/dashboard-certification-detail';
import ChangePassword from 'pages/changePassword';
import ContentLibrary from 'pages/contentLibrary';
import ContentLibraryDetailPage from 'pages/content-library-detail';
import CoursesDetail from 'pages/administator/courses-detail';
import CoursesPage from 'pages/administator/courses';
import CourseUsersPage from 'pages/administator/course-users';
import Dashboard from 'pages/dashboard';
import ErrorLogin from 'pages/error-login';
import EvaluationDetailsPage from 'pages/administator/evaluation-details';
import EvaluationQuestionGroupPage from 'pages/administator/evaluation-question-group';
import Login from 'pages/login';
import MyCourseDetailsPage from 'pages/my-course-details';
import MyCoursesLaunchPage from 'pages/my-course-launch';
import MyTranscriptPage from 'pages/transcript';
import MyTranscriptReportPage from 'pages/transcript-report';
import NewEvaluationQuestionGroupPage from 'pages/administator/new-evaluation-question-group';
import NewGroupPage from 'pages/administator/new-group';
import NewRatingTypePage from 'pages/administator/new-rating-type';
import NewUserPage from 'pages/administator/new-user';
import NoPageError from 'pages/404';
import Profile from 'pages/profile';
import ProtectedAdminRoutes from 'components/ProtectedAdminRoutes';
import ProtectedRoutes from 'components/ProtectedRoutes';
import QuestionDetailPage from 'pages/administator/questions-details';
import QuestionNewPage from 'pages/administator/questions-new';
import QuestionsPage from 'pages/administator/questions';
import RatingTypesPage from 'pages/administator/rating-types';
import Report from 'pages/administator/report';
import ReportsPage from 'pages/administator/reports';
import ScormCurses from 'pages/administator/scorm-courses';
import ScormCursesDetail from 'pages/administator/scorm-courses-detail';
import ScormCursesNew from 'pages/administator/scorm-courses-new';
import Sso from 'pages/sso';
import SuccessionPlanning from 'pages/successionPlanning';
import AdminUserProfilePage from 'pages/administator/admin-user-profile';
import AdminUserAddGroupPage from 'pages/administator/admin-user-add-group';
import AdminUserEditGroupPage from 'pages/administator/admin-user-edit-group';
import CourseInstancesPage from 'pages/administator/course-instances';
import AdminManageGroupsPage from 'pages/administator/admin-manage-groups';
import AdminGroupUsersPage from 'pages/administator/admin-group-users';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:webApiClientId/login" element={<Login />} />
        <Route path="questions" element={<QuestionsPage />} />
        <Route path="questions/new" element={<QuestionNewPage />} />
        <Route path="questions/detail/:questionId" element={<QuestionDetailPage />} />
        <Route path="/sso/:accessToken/:userId/:webApiClientId" element={<Sso />} />
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/course/detail/:courseId" element={<MyCourseDetailsPage />} />
          <Route path="dashboard/course/launch/:courseId" element={<MyCoursesLaunchPage />} />
          <Route path="dashboard/certification/detail/:certificationId" element={<CertificationDetailsPage />} />
          <Route path="dashboard/assignment/detail/:certificationId" element={<CertificationDetailsPage />} />
          <Route path="content-library" element={<ContentLibrary />} />
          <Route path="content-library-detail" element={<ContentLibraryDetailPage />} />
          <Route path="transcript" element={<MyTranscriptPage />} />
          <Route path="transcript-report/:userId" element={<MyTranscriptReportPage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="succession-planning" element={<SuccessionPlanning />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="admin" element={<ProtectedAdminRoutes />}>
            <Route path="courses" element={<CoursesPage />} />
            <Route path="courses/users" element={<CourseUsersPage />} />
            <Route path="courses/instances/:learningObjectId" element={<CourseInstancesPage />} />
            <Route path={NAVIGATION_URL.ADMIN_SCORM} element={<ScormCurses />} />
            <Route path={NAVIGATION_URL.ADMIN_SCORM_NEW} element={<ScormCursesNew />} />
            <Route path={`${NAVIGATION_URL.ADMIN_SCORM_DETAIL}/:scormCourseId`} element={<ScormCursesDetail />} />
            <Route path="courses/detail/:courseId/:courseName" element={<CoursesDetail />} />
            <Route path="reports" element={<ReportsPage />} />
            <Route path="reports/:reportId/:reportName" element={<Report />} />
            <Route path="evaluations" element={<AdminEvaluationsPage />} />
            <Route path="evaluations/details/:evaluationId" element={<EvaluationDetailsPage />} />
            <Route path="rating-types" element={<RatingTypesPage />} />
            <Route path="rating-types/new" element={<NewRatingTypePage />} />
            <Route path="evaluation-question-group-list" element={<EvaluationQuestionGroupPage />} />
            <Route path="evaluation-question-group-list/new" element={<NewEvaluationQuestionGroupPage />} />
            <Route path="groups" element={<AdminManageGroupsPage />} />
            <Route path="groups/new" element={<NewGroupPage />} />
            <Route path="groups/users/:groupId" element={<AdminGroupUsersPage />} />
            <Route path="users" element={<AdminUsersPage />} />
            <Route path="user/profile/:userId" element={<AdminUserProfilePage />} />
            <Route path="user/profile/add-new-group/:userId" element={<AdminUserAddGroupPage />} />
            <Route path="user/profile/edit-group/:userId" element={<AdminUserEditGroupPage />} />
            <Route path="users/new" element={<NewUserPage />} />
            <Route path="test-libraries" element={<AdminTestLibrariesPage />} />
            <Route path="test-libraries/new" element={<AdminTestLibrariesNewPage />} />
            <Route path="test-libraries/detail/:testLibraryId" element={<AdminTestLibrariesDetailPage />} />
            <Route path="questions" element={<QuestionsPage />} />
            <Route path="questions/new" element={<QuestionNewPage />} />
            <Route path="questions/detail/:questionId" element={<QuestionDetailPage />} />
          </Route>
        </Route>
        <Route path="/error-login" element={<ErrorLogin />} />
        <Route path="*" element={<NoPageError />} />
      </Routes>
    </Router>
  );
}

export default App;
