/* eslint-disable max-len */
import React from 'react';
import { Button, Tooltip } from '@mui/material';
import ViewFormLayout from 'components/ViewFormLayout';
import { ACTION_ITEM_TOOLTIP_TEXT, BUTTON_TEXT } from 'utils/constants';
import { UserManagementRequest } from 'services/interfaces';
import { useNavigate } from 'react-router-dom';
import UsersTable from './UserTable';

export default function ManageGroupsUsers({ groupId }: { groupId: string }) {
  const INITIAL_FILTERS: UserManagementRequest = {
    userId: null,
    firstName: null,
    lastName: null,
    middleName: null,
    userName: null,
    email: null,
    employeeId: null,
    division: null,
    active: null,
    jobCodeDescription: null,
    jobClassDescription: null,
    jobPositionDescription: null,
    PageNumber: 1,
    PageSize: 10,
    SortingBy: 'userName',
    Ascending: true,
    groupId: Number(groupId),
  }
  const navigate = useNavigate();
  return (
    <ViewFormLayout pl={0} pr={0} testId="users-list">
      <ViewFormLayout.ActionBar>
        <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.BACK}>
          <Button variant="text" onClick={() => navigate(-1)}>{BUTTON_TEXT.BACK}</Button>
        </Tooltip>
      </ViewFormLayout.ActionBar>
      <ViewFormLayout.Body>
        <UsersTable filters={INITIAL_FILTERS} />
      </ViewFormLayout.Body>
    </ViewFormLayout>
  );
}
