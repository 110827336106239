import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import ManageGroupsUsers from 'modules/administrator/AdminManageGroups/ManageGroupsUsers';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';

function AdminGroupUsersPage() {
  const { groupId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage(`Users for Group: ${groupId}`));
  }, []);

  if (!groupId) {
    return (
      <Layout>
        <Typography>The group id is not defined!</Typography>
      </Layout>
    )
  }

  return (
    <Layout>
      <ManageGroupsUsers groupId={groupId} />
    </Layout>
  );
}

export default AdminGroupUsersPage;
