import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, IconButton, Tooltip,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { ACTION_ITEM_TOOLTIP_TEXT } from 'utils/constants';
import { GroupsManagement, GroupsManagementRequest } from 'services/interfaces';
import { useGetGroupsManagementMutation } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import useRemoteTable from 'components/RemoteCustomTable/useRemoteTable';
import RemoteCustomTable from 'components/RemoteCustomTable';
import GroupIcon from '@mui/icons-material/Group';

function getActionButtons(_navigate: Function) {
  return (
    <Box display="flex">
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.EDIT_GROUP}>
        <IconButton onClick={() => _navigate('/admin/groups/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <EditIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_GROUP}>
        <IconButton onClick={() => _navigate('/admin/groups/new')} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <VisibilityIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

function getUserCountButton(id: string | number, _navigate: Function, count: number) {
  return (
    <Tooltip title={ACTION_ITEM_TOOLTIP_TEXT.VIEW_STUDENTS}>
      <IconButton sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => _navigate(`users/${id}`)}>
        <GroupIcon sx={{ color: (theme) => theme.palette.primary.main }} />
        <Typography color={(theme) => theme.palette.primary.main}>
          {`View (${count})`}
        </Typography>
      </IconButton>
    </Tooltip>
  )
}

function createData(items: GroupsManagement[], _navigate: Function): TRows[] {
  return items?.map((item: GroupsManagement) => ({
    ...item,
    id: Number(item.groupId),
    isCollapsable: false,
    details: [],
    // userCount: getUserCountButton(item.userCount, _navigate),
    actions: getActionButtons(_navigate),
    userCount: getUserCountButton(item.groupId, _navigate, item.userCount),
  }));
}

export default function GroupsTable({ filters }:
  {filters: GroupsManagementRequest}) {
  const [getGroupsByFilter] = useGetGroupsManagementMutation()

  const {
    dataSet,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sort,
    setSort,
    sortDirection,
    setSortDirection,
    isLoading,
  } = useRemoteTable<GroupsManagement, GroupsManagementRequest>({
    fetchData: (request) => getGroupsByFilter(request).unwrap(),
    filters,
    sortDefault: 'groupId',
  });

  const navigate = useNavigate();

  const columns: TColumns[] = [
    {
      name: 'Group ID',
      column: 'groupId',
      width: 200,
      sortable: true,
    },
    {
      name: 'Name',
      column: 'name',
      width: 120,
      sortable: true,
    },
    {
      name: 'Description',
      column: 'description',
      width: 120,
      sortable: false,
    },
    {
      name: 'User Count',
      column: 'userCount',
      width: 120,
      sortable: false,
    },
  ];

  if (isLoading) {
    return <LoadingBox />
  }

  return (
    <RemoteCustomTable
      rows={createData(dataSet?.data || [], navigate)}
      columns={columns}
      totalRecords={dataSet?.totalRows || 0}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sort={sort}
      setSort={setSort}
    />
  )
}
