import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { useDispatch } from 'react-redux';
import { setNavigationPage } from 'redux/userProfileSlice';
import AdminManageGroups from 'modules/administrator/AdminManageGroups';

function AdminManageGroupsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setNavigationPage('Groups'));
  }, []);

  return (
    <Layout>
      <AdminManageGroups />
    </Layout>
  );
}

export default AdminManageGroupsPage;
