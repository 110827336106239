import React from 'react';
import { TColumns, TRows } from 'components/CustomTable/interface';
import { UserManagement, UserManagementRequest } from 'services/interfaces';
import { useGetUserManagementMutation } from 'services/dataApi';
import LoadingBox from 'components/LoadingBox';
import useRemoteTable from 'components/RemoteCustomTable/useRemoteTable';
import RemoteCustomTable from 'components/RemoteCustomTable';

function createData(items: UserManagement[]): TRows[] {
  return items?.map((item: UserManagement) => ({
    ...item,
    id: Number(item.userId),
    isCollapsable: false,
    active: item?.active ? 'True' : 'False',
    details: [],
  }));
}

export default function UsersTable({ filters }: {filters: UserManagementRequest}) {
  const [getUsersByGroupId] = useGetUserManagementMutation()

  const {
    dataSet,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sort,
    setSort,
    sortDirection,
    setSortDirection,
    isLoading,
  } = useRemoteTable<UserManagement, UserManagementRequest>({
    fetchData: (request) => getUsersByGroupId(request).unwrap(),
    filters,
    sortDefault: 'userName',
  });

  const columns: TColumns[] = [
    {
      name: 'User ID',
      column: 'userId',
      width: 150,
      sortable: true,
    },
    {
      name: 'User Name',
      column: 'userName',
      width: 200,
      sortable: true,
    },
    {
      name: 'First Name',
      column: 'firstName',
      width: 150,
      sortable: true,
    },
    {
      name: 'Last Name',
      column: 'lastName',
      width: 150,
      sortable: true,
    },
    {
      name: 'Employee ID',
      column: 'employeeId',
      width: 150,
      sortable: true,
    },
    {
      name: 'Active',
      column: 'active',
      width: 100,
      sortable: false,
    },
  ];

  if (isLoading) {
    return <LoadingBox />
  }

  return (
    <RemoteCustomTable
      rows={createData(dataSet?.data || [])}
      columns={columns}
      totalRecords={dataSet?.totalRows || 0}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      sortDirection={sortDirection}
      setSortDirection={setSortDirection}
      sort={sort}
      setSort={setSort}
    />
  )
}
